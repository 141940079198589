import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export default function PageCard({title, icon, color, setContent, content}) {
    const CardStyle = {
        borderRadius: '3%',
        borderBottomRightRadius: "50px",
        width: "100%",
        cursor: 'pointer',
        boxShadow: '5px 5px 5px #04bd57',
        backgroundColor: color
    }
    return <>
        <div className={`card h-100 shadow-lg shadow home-card text-white border-darker border-2 mb-3`}
             style={CardStyle} onClick={() => {
                 setContent(content)
        }}>
            <div className="card-body mt-3 text-center">
                <FontAwesomeIcon className='mt-0 mb-0' icon={icon} size={'4x'}/>
                <h4 className="card-title mt-2 text-start">{title} </h4>
            </div>
        </div>
    </>
}
