import Breadcrumb from "../Components/Breadcrumb";
import PageTitle from "../Components/PageTitle";
import React from "react";
import ContactForm from "../Components/Form";
import ContactDetails from "../Components/Details";
import Map from "../Components/Map";
import SeoTitle from "Components/SeoTitle";

export default function Contact() {
    const breadcrumbs = [{name: 'contact us'}]
    return <>
        <SeoTitle title={'Contact Us'}/>
        <div className='container'>
            <div className='row'>
                <Breadcrumb list={breadcrumbs}/>
            </div>
            <div className='row'>
                <PageTitle title={'contact us'}/>
            </div>
            <div className="row  mt-3 mb-5">
                <div className='col-md-6'>
                    <ContactForm/>
                </div>
                <div className='col-md-6'>
                    <ContactDetails/>
                </div>
            </div>
        </div>
        <div className='row'>
            <Map/>
        </div>
    </>
}
