import React, {useEffect, useState} from "react";
import {ApiConsumer} from "api/Api";
import {ContentRoutes} from "routes/content";
import ReactMarkdown from "react-markdown";

export default function Resources({limit = 100}) {
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState([])

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(ContentRoutes.RESOURCES.RESOURCES)
            .then(res => setContent(res.data))
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return <>
        <div className="row">
            <div className='col-12' style={{color: '#636e72'}}>
                <h3 className='mt-5 mb-5 text-center'>Resources</h3>
                <div className='container mb-5'>
                    {
                        !loading ?
                            <div className='row'>
                                {
                                    content.slice(0, limit).map(item => {
                                        return <div key={item.name} className='col-lg-3 col-md-6 d-flex align-items-stretch'>
                                            <div className="card shadow-none border-darker border-2">
                                                <img
                                                    src={`https://cms.nsztema.co.za/${item?.image?.formats?.small?.url ?? item?.image?.formats?.thumbnail?.url}`}
                                                    className="card-img-top" alt={item.name} loading='lazy' width='296' height='165'/>
                                                <div className="card-body">
                                                    <h5 className="card-title fw-bold">{item.name}</h5>
                                                    <p className="card-text">
                                                        <ReactMarkdown>{item.introduction}</ReactMarkdown>
                                                    </p>
                                                </div>
                                                <p className="card-text mt-auto">
                                                    <small className="text-muted">
                                                        <a href={item.link.includes('http') ? item.link : `https://cms.nsztema.co.za${item.link}`} target='_blank' rel="noreferrer nofollow"
                                                           className="float-end tx-primary px-2 py-2">Read More...</a>
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div> : null
                    }
                    {
                        loading ? <div className='row'>
                            <div className='col-12'>
                                <p className='text-center'>
                                    <div className="spinner-grow text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </p>
                            </div>
                        </div> : null
                    }
                </div>
            </div>
        </div>
    </>
}
