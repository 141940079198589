import React, {lazy, useState} from 'react';
import {Switch, Route, useHistory, BrowserRouter} from "react-router-dom"
import Home from "./Pages/Home";
import {UrlContext} from "contexts/UrlContext";
import {ContentRoutes} from "routes/content";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Resources from "./Pages/Resources";
import Contact from "./Pages/Contact";
import GoogleAnalytics from "./Components/GoogleAnalytics";
import {HelmetProvider} from 'react-helmet-async';
import Popi from "./Pages/Popi";
import Terms from "./Pages/Terms";
import Information from "./Pages/Information";
const Navbar = lazy(() => import('Components/Navbar'));
const Footer = lazy(() => import('Components/Footer'));
const ScrollToTop = lazy(() => import('Components/ScrollToTop'));

export default function App() {
    let history = useHistory();
    const [url, setUrl] = useState(ContentRoutes.PAGES.ABOUT);

    return (
        <HelmetProvider>
            <UrlContext.Provider value={{url, setUrl}}>
                <BrowserRouter history={history}>
                    <div className="App">
                        <Navbar/>
                        <ScrollToTop/>
                        <GoogleAnalytics/>
                        <Switch>
                            <Route path='/' component={Home} exact/>
                            <Route path='/about' component={About} exact/>
                            <Route path='/services' component={Services} exact/>
                            <Route path='/resources' component={Resources} exact/>
                            <Route path='/info' component={Information} exact/>
                            <Route path='/contact' component={Contact} exact/>
                            <Route path='/popi' component={Popi} exact/>
                            <Route path='/terms' component={Terms} exact/>
                        </Switch>
                        <Footer/>
                    </div>
                </BrowserRouter>
            </UrlContext.Provider>
        </HelmetProvider>
    );
}
