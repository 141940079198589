export default function ContactDetails() {
    function triggerMail() {
        window.open("mailto:info@nsztema.co.za", '_blank').focus();
    }

    return <>
        <div className='card shadow-none border-darker border-2 ' style={{background: '#2f3542', color: '#fff'}}>
            <div className='container mt-3'>
                <div className='row'>
                    <div className='col-12'>
                        <div className="row g-3 mb-2">
                            <div className="col">
                                <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">address</label>
                                <p>22 1st Ave<br/> Melville<br/>Johannesburg<br/>2092</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className="mb-3">
                            <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">phone</label>
                            <p>011 482 9007</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className="mb-3" style={{cursor: "pointer"}} onClick={triggerMail}>
                            <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold">email</label>
                            <p>info@nsztema.co.za</p>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className="mb-4">
                            <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold">working
                                hours</label>
                            <p>Monday to Thursday - 09:00 to 17:30<br/>
                                Friday - 09:00 to 16:30<br/>
                                Saturday - 09:00 to 12:00<br/>
                                Sunday - Closed
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
