import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarAlt, faPeopleArrows, faBookReader, faUsers, faHeadphonesAlt} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";


export default function Banner() {
    const history = useHistory();
    const CardStyle = {
        borderRadius: '3%',
        borderBottomRightRadius: "10%",
        width: "18rem",
        height: "350px",
        cursor: 'pointer',
        autoplaySpeed: 2000,
        pauseOnHover: true,
        color: '#fff'
    }

    return (
        <div>
            <header className="text-center text-white masthead  h-100 banner w-100"
                    style={{
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: "cover",
                        objectFit: "fill",
                        width: "100%",
                        height: "100%"
                    }}>
                <div className='container mt-0 p-5'>
                    <div className='row'>
                        <div
                            className='col-lg-12 col-md-12 align-items-stretch align-items-center fw-bold text-black-50 fw-bold'>
                            <h1 style={{marginTop: "100px", marginBottom: "100px"}} className='fw-bold '>Integrated
                                Child Health & Wellness</h1>
                        </div>
                    </div>
                    <div className="card-group">
                        <div className="row row-cols-1 row-cols-md-4 g-4">
                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                                <a className="card danger h-100 shadow-lg shadow home-card border-darker border-2"
                                   style={CardStyle} href="https://caregiversnote.nsztema.co.za/" target='_blank'
                                   rel='nofollow'>
                                    <div className="card-body mt-3">
                                        <FontAwesomeIcon className='mt-5' icon={faHeadphonesAlt} size={'7x'}/>
                                        <h2 className="card-title mt-5">caregivers note podcast </h2>
                                    </div>
                                </a>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                                <div className="card warning h-100 shadow-lg shadow home-card border-darker border-2"
                                     style={CardStyle}
                                     onClick={() => history.push('/services')}>
                                    <div className="card-body mt-3">
                                        <FontAwesomeIcon className='mt-5' icon={faPeopleArrows} size={'7x'}/>
                                        <h2 className="card-title mt-5">services </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                                <div className="card primary h-100 shadow-lg shadow home-card border-darker border-2"
                                     style={CardStyle}
                                     onClick={() => history.push('/resources')}>
                                    <div className="card-body mt-3">
                                        <FontAwesomeIcon className='mt-5' icon={faBookReader} size={'7x'}/>
                                        <h2 className="card-title mt-5">resources </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                                <div className="card success h-100 shadow-lg shadow home-card border-darker border-2"
                                     style={CardStyle}
                                     onClick={() => history.push('/about')}>
                                    <div className="card-body mt-3">
                                        <FontAwesomeIcon className='mt-5' icon={faUsers} size={'7x'}/>
                                        <h2 className="card-title mt-5">about us </h2>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}
