import Breadcrumb from "../Components/Breadcrumb";
import PageTitle from "../Components/PageTitle";
import PageCard from "../Components/PageCard";
import React, {useEffect, useState} from "react";
import {ApiConsumer} from "../api/Api";
import {ContentRoutes} from "../routes/content";
import {
    faCalendarAlt, faHeadphonesAlt,
    faHospital,
    faPeopleArrows
} from "@fortawesome/free-solid-svg-icons";
import ReactMarkdown from "react-markdown";
import {useHistory} from "react-router-dom";
import SeoTitle from "Components/SeoTitle";

export default function Resources() {
    const breadcrumbs = [{name: 'resources'}]
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState([])

    const history = useHistory();

    function changePage(url) {
        history.push(url)
    }

    function goToAppointments(url) {
        window.open(url, '_blank').focus();
    }

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(ContentRoutes.RESOURCES.RESOURCES)
            .then(res => setContent(res.data))
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
                //scroll()
            })
    }, [])

    return <>
        <SeoTitle title={'Resources'}/>
        <div className='container'>
            <div className='row'>
                <Breadcrumb list={breadcrumbs}/>
            </div>
            <div className='row'>
                <PageTitle title={'resources'}/>
            </div>
            <div className="row  mt-3 mb-5">
                <div className='col-md-8'>
                    <div className='row'>
                        {
                            !loading ? content.map(item => {
                                return <div key={item.name} className='col-lg-6 col-md-6 align-items-stretch'>
                                    <div className="card shadow-none border-darker border-2">
                                        <img
                                            src={`https://cms.nsztema.co.za/${item?.image?.formats?.small?.url ?? item?.image?.formats?.thumbnail?.url}`}
                                            width={352} height={197}
                                            className="card-img-top" alt="..."/>
                                        <div className="card-body">
                                            <h5 className="card-title fw-bold">{item.name}</h5>
                                            <p className="card-text">
                                                <ReactMarkdown>{item.introduction}</ReactMarkdown>
                                            </p>
                                        </div>
                                        <p className="card-text mt-auto">
                                            <small className="text-muted">
                                                <a href={item.link.includes('http') ? item.link : `https://cms.nsztema.co.za${item.link}`}
                                                   target='_blank' rel="noreferrer"
                                                   className="float-end tx-primary px-2 py-2">Read More...</a>
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            }) : null
                        }
                    </div>
                    {
                        loading ? <div className='row'>
                            <div className='col-12'>
                                <p className='text-center'>
                                    <div className="spinner-grow text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </p>
                            </div>
                        </div> : null
                    }
                </div>
                <div className='col-md-4'>
                    <div className="row row-cols-2 row-cols-md-2 g-2">
                        <div className="col">
                            <PageCard title={'about us'} color={'#4a94b4'} icon={faHospital} content={'/about'}
                                      setContent={changePage}/>
                        </div>
                        <div className="col">
                            <PageCard title={'our services'} color={'#74c9db'} icon={faPeopleArrows}
                                      content={'/services'} setContent={changePage}/>
                        </div>
                        <div className="col">
                            <PageCard title={'the caregivers note podcast'} color={'#1f495b'} icon={faHeadphonesAlt}
                                      content={'https://caregiversnote.nsztema.co.za/'} setContent={goToAppointments}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<ProcessFlow/>*/}
    </>
}
