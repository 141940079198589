import Breadcrumb from "../Components/Breadcrumb";
import PageTitle from "../Components/PageTitle";
import PageContent from "../Components/PageContent";
import PageCard from "../Components/PageCard";
import React, {useEffect, useState} from "react";
import {ApiConsumer} from "../api/Api";
import {ContentRoutes} from "../routes/content";
import Resources from "../Components/Resources";
import {faUtensils, faUsersCog, faBaby, faPeopleArrows} from "@fortawesome/free-solid-svg-icons";
import SeoTitle from "Components/SeoTitle";

export default function Services() {
    const breadcrumbs = [{name: 'our services'}]
    const [loading, setLoading] = useState(true)
    const [extraLoading, setExtraLoading] = useState(true)
    const [content, setContent] = useState('')
    const [extra, setExtra] = useState([])
    const [services, setServices] = useState('')

    function getIcon(icon) {
        if (icon === "faBaby")
            return faBaby;
        if (icon === 'faUtensils')
            return faUtensils;
        if (icon === 'faUsersCog')
            return faUsersCog;
    }


    function loadContent() {
        ApiConsumer.get(ContentRoutes.PAGES.SERVICES)
            .then(res => {
                setContent(res.data)
                setServices(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
            })
    }

    function loadExtra() {
        ApiConsumer.get(ContentRoutes.SERVICES.SERVICES)
            .then(res => setExtra(res.data))
            .catch(err => console.error(err))
            .finally(() => {
                setExtraLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        setExtraLoading(true)
        loadContent()
        loadExtra()
    }, [])

    return <>
        <SeoTitle title={'Our Services'} />
        <div className='container'>
            <div className='row'>
                <Breadcrumb list={breadcrumbs}/>
            </div>
            <div className='row'>
                <PageTitle title={'our services'}/>
            </div>
            <div className="row  mt-3 mb-5">
                <div className='col-md-8'>
                    <PageContent content={content?.content ?? ''} loading={loading} tabs={content?.infotab}/>
                </div>
                <div className='col-md-4'>
                    <div className="row row-cols-2 row-cols-md-2 g-2">
                        <div className="col">
                            <PageCard title={'our services'} color={'#40739e'} icon={faPeopleArrows}
                                      content={services} setContent={setContent}/>
                        </div>
                        {
                            extra.map(item => {
                                return <div className="col">
                                    <PageCard title={item.name} color={item.color}
                                              icon={getIcon(item?.fontawesomeicon)}
                                              content={item}
                                              setContent={setContent}
                                    />
                                </div>
                            })
                        }
                    </div>
                    {
                        extraLoading ? <div className='row'>
                            <div className='col-12'>
                                <p className='text-center'>
                                    <div className="spinner-grow tx-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </p>
                            </div>
                        </div> : null
                    }
                </div>
            </div>
        </div>
        {/*<ProcessFlow/>*/}
        <Resources/>
    </>
}
