import Banner from 'Components/Banner'
import Resources from "Components/Resources";
import CallToAction from "Components/CallToAction";

export default function Home() {
    return <>
        <Banner/>
        <CallToAction/>
        <Resources limit={4}/>
    </>
}
