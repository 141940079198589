import ReactMarkdown from "react-markdown";
import {Accordion} from "react-bootstrap";

export default function PageContent({content, tabs=[], loading = true}) {
    const CardStyle = {
        borderRadius: '5px',
        width: "100%",
        boxShadow: '5px 5px 5px #04bd57'
    }
    return <>
        <div className="card bg-white h-100 shadow-none border-darker border-2" style={CardStyle}>
            <div className="card-body p-5">
                {
                    !loading ? <ReactMarkdown>{content}</ReactMarkdown> : null
                }
                {
                    loading ? <div className='row'>
                        <div className='col-12'>
                            <p className='text-center'>
                                <div className="spinner-grow tx-success" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </p>
                        </div>
                    </div> : null
                }
                {
                    tabs.map((content, index) => {
                        return <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey={index}>
                                <Accordion.Header>{content.title}</Accordion.Header>
                                <Accordion.Body>
                                    <ReactMarkdown>{content.content}</ReactMarkdown>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    })
                }
            </div>
        </div>
    </>
}
