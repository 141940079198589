import {Helmet} from "react-helmet-async";

export default function SeoTitle({
                                     title,
                                     description = '',
                                     keywords = 'academic difficulties, behavioural difficulties, cognitive difficulties, development difficulties, emotional difficulties'
                                 }) {

    return <>
        <Helmet>
            <title>Dr Tema & Associates Inc - {title}</title>
            <meta
                name="description"
                content={description}
            />
            <meta
                name="keywords"
                content={keywords}
            />
        </Helmet>
    </>
}
