const BASE_URL = 'https://cms.nsztema.co.za'
export const ContentRoutes = {
    PAGES : {
        ABOUT: `${BASE_URL}/pages/1`,
        SERVICES: `${BASE_URL}/pages/2`,
        RESOURCES: `${BASE_URL}/pages/3`,
        INFORMATION: `${BASE_URL}/pages/6`,
        POPI: `${BASE_URL}/pages/5`,
        TERMS: `${BASE_URL}/pages/4`,

    },
    RESOURCES : {
        RESOURCES: `${BASE_URL}/resources/`
    },
    SERVICES : {
        SERVICES: `${BASE_URL}/services/`
    }
}
