import ReactGA from 'react-ga';
import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export default function GoogleAnalytics() {
    const { pathname } = useLocation();

    useEffect(() => {
        ReactGA.initialize('G-DE5XB3T26V');
        ReactGA.pageview(pathname);
    }, [pathname]);
    return <></>
}
