import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faHospitalUser} from "@fortawesome/free-solid-svg-icons";
import React from "react";

export default function CallToAction() {
    const CardStyle = {
        borderBottomRightRadius: "10%",
        height: "350px",
        autoplaySpeed: 2000,
        pauseOnHover: true,
        color: '#fff'
    }

    return <>
        <div className='container p-5'>
        <div className="success h-100 shadow-lg shadow home-card border-darker border-2 card p-4 home-card" style={CardStyle}>
            <div className="container space-2">
                <div className="row align-items-center">
                    <div className="col-md-2 text-md-right">
                        <FontAwesomeIcon icon={faHospitalUser} size={'6x'}/>
                    </div>
                    <div className="col-md-8 mb-4 mb-md-0">
                        <h3 className="mb-3 mt-3 text-white">Take the first step towards better mental health today. Click now to book your appointment with Dr Tema.</h3>
                    </div>
                    <div className="col-md-2 text-md-right">
                        <div className="input-group ml-sm-auto">
                            <div className="input-group-append">
                                <a href="https://clavkoo.org" target="_blank" rel="noreferrer">
                                    <button className="btn btn-md process text-white fw-bold"
                                            style={{background: '#00b894', borderRadius: "50px"}}
                                            type="button"> Go to Clavkoo {' '} <FontAwesomeIcon
                                        icon={faArrowRight}/>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </>
}
