import React from 'react';
import {createRoot} from 'react-dom/client';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import 'bootstrap/dist/css/bootstrap.min.css'
import "./index.css"
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
    dsn: "https://0dc39c9469ad41d0a25233eafcb7d99f@o86758.ingest.sentry.io/6424887",
    integrations: [new BrowserTracing()],
    environment: 'production',
    tracesSampleRate: 1.0,
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(<App tab="app"/>);

reportWebVitals();
