import Breadcrumb from "./Breadcrumb";
import PageTitle from "./PageTitle";
import PageContent from "./PageContent";
import React, {useEffect, useState} from "react";
import {ApiConsumer} from "api/Api";
import {ContentRoutes} from "routes/content";

export default function Content({url = ContentRoutes.PAGES.ABOUT, breadcrumb = 'about us'}) {
    const breadcrumbs = [{name: breadcrumb}]
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState('')

    function loadContent() {
        ApiConsumer.get(url)
            .then(res => {
                setContent(res.data)
                console.log(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true)
        loadContent()
    }, [])


    return <>
        <div className='container'>
            <div className='row'>
                <Breadcrumb list={breadcrumbs}/>
            </div>
            <div className='row text-center'>
                <PageTitle title={breadcrumb}/>
            </div>
            <div className="row  mt-3 mb-5">
                <div className='col-md-12'>
                    <PageContent content={content?.content ?? ''} loading={loading} tabs={content?.infotab}/>
                </div>
            </div>
        </div>
    </>
}
