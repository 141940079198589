export default function Breadcrumb({list = []}) {
    return <>
        <nav
            className='mt-3'
            style={{
                "--bs-breadcrumb-divider": "url(&#34;data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E&#34;);",
                ariaLabel: "breadcrumb", textDecoration:'none', color: '#636e72'
            }}>
            <ol className="breadcrumb">
                <li className="breadcrumb-item text-decoration-none"><a href="#">Home</a></li>
                {
                    list.map((item, index) => {
                        return <li key={index} className="breadcrumb-item active text-decoration-none" aria-current="page">{item.name}</li>
                    })
                }
            </ol>
        </nav>
    </>
}
