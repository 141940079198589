import Breadcrumb from "../Components/Breadcrumb";
import PageTitle from "../Components/PageTitle";
import PageContent from "../Components/PageContent";
import PageCard from "../Components/PageCard";
import React, {useEffect, useState} from "react";
import {ApiConsumer} from "api/Api";
import {ContentRoutes} from "routes/content";
import {faHeadphonesAlt, faHospital, faPeopleArrows} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";
import SeoTitle from "Components/SeoTitle";

export default function About() {
    const breadcrumbs = [{name: 'about us'}]
    const [loading, setLoading] = useState(true)
    const [content, setContent] = useState('')

    const history = useHistory();

    function changePage(url) {
        history.push(url)
    }

    function goToAppointments(url) {
        window.open(url, '_blank').focus();
    }

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(ContentRoutes.PAGES.ABOUT)
            .then(res => setContent(res.data))
            .catch(err => console.error(err))
            .finally(() => {
                setLoading(false)
            })
    }, [])

    return <>
        <SeoTitle title={'About Us'}/>
        <div className='container'>
            <div className='row'>
                <Breadcrumb list={breadcrumbs}/>
            </div>
            <div className='row'>
                <PageTitle title={'about us'}/>
            </div>
            <div className="row  mt-3 mb-5">
                <div className='col-md-8'>
                    <PageContent content={content?.content ?? ''} loading={loading} tabs={content?.infotab}/>
                </div>
                <div className='col-md-4'>
                    <div className="row row-cols-2 row-cols-md-2 g-2">
                        <div className="col">
                            <PageCard title={'about us'} color={'#4a94b4'} icon={faHospital} content={'/about'}
                                      setContent={changePage}/>
                        </div>
                        <div className="col">
                            <PageCard title={'our services'} color={'#74c9db'} icon={faPeopleArrows}
                                      content={'/services'} setContent={changePage}/>
                        </div>
                        <div className="col">
                            <PageCard title={'the caregivers note podcast'} color={'#1f495b'} icon={faHeadphonesAlt}
                                      content={'https://caregiversnote.nsztema.co.za/'} setContent={goToAppointments}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/*<ProcessFlow/>*/}
    </>
}
